import './recent-work.scss'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function RecentWork() {


  useEffect(() => {
    // Large Iframe Event
    document.querySelector('.large-iframe').addEventListener('mouseover', function(){
      document.querySelector('.recent-work-small-video').style.opacity = '0.3'
  
    })
    document.querySelector('.large-iframe').addEventListener('mouseleave', function(){
      document.querySelector('.recent-work-small-video').style.opacity = '1.0'
    })
    // Small Iframe Event
    document.querySelector('.recent-work-small-video').addEventListener('mouseover', function(){
      document.querySelector('.large-iframe').style.opacity = '0.3'
  
    })
    document.querySelector('.recent-work-small-video').addEventListener('mouseleave', function(){
      document.querySelector('.large-iframe').style.opacity = '1.0'
    })
  })

  return (
    <section class='recent-work-section'>
      <div className="recent-work-heading">
        <h3>Recent Work</h3>
      </div>
      <div className="recent-work-videos">
        {/*  */}
        <div className="recent-work-video">
          <div className="recent-work-large-video">
            <iframe className='large-iframe' width="560" height="315" src='https://www.youtube-nocookie.com/embed/YKnXWyEUns0' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
{/* Small Video */}
          <div className="recent-work-small-video">
            <iframe width="560" height="315" src='https://www.youtube-nocookie.com/embed/Un6KCnfKaNw' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          </div>        
        </div>
      </div>

      {/* Button */}
      <div className="recent-work-button-div">
        <Link className="recent-work-button">
          See More
        </Link>
      </div>
    </section>
  )
}
