import AboutContent from './about-content'
import AboutHeader from './about-header'
import './about.css'
import Invitation from '../home/invitation'
import { useRef , useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

export default function About() {
  let headingRef = useRef(null);
  let bigHeadingRef = useRef(null);
  let containerRef = useRef(null);
  let firsth4ref = useRef(null);
  let secondh4ref = useRef(null);
  let thirdh4ref = useRef(null);
  let textRef = useRef(null)
  let textTriggerRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

gsap.registerPlugin(TextPlugin);
  const comp = useRef();
  useEffect(()=>{
    let height = window.innerHeight;
    console.log(height)
    let aboutScroll = gsap.context(()=>{
    //   const timeline = gsap.timeline({scrollTrigger:{
    //     trigger: bigHeadingRef.current,
    //     start: 'top top',
    //     end: `+=${height}`,
    //     pin: containerRef.current,     
    //     pinSpacing: true,
    //    //  scrub: true ,
    //    //  invalidateOnRefresh: true,
    //    scrub: true
    //     // anticipatePin: 1
  
    // }})
    // timeline.fromTo(bigHeadingRef.current,{translateX: '0vh'},{translateX: '100vw',duration:5})
    // timeline.fromTo(headingRef.current,{translateY: '100vh'},{translateY: '0vh',duration:5})
    // timeline.fromTo(firsth4ref.current,{translateY: '100vh'},{translateY: '0vh',duration:5})
    // timeline.fromTo(secondh4ref.current,{translateY: '100vh'},{translateY: '0vh',duration:5})
    // timeline.fromTo(thirdh4ref.current,{translateY: '100vh'},{translateY: '0vh',duration:5})      
     } , comp)

     let textAnimate = gsap.context(()=>{
      let textTimeline = gsap.timeline({
      
        scrollTrigger:{
          trigger: textTriggerRef.current,
          pin: textTriggerRef.current,
          start: "top top",
          // end: "center top",
          scrub: true,
          // markers: true
        }
      })
      textTimeline.to(textRef.current, 
        {duration: 3 , 
        text: {
        value: 'huzaifa haroon is the biggest don of this world'
      }

      })
     } , comp)
     
     return ()=>
     {
      aboutScroll.revert();
      textAnimate.revert();
     }
   
  })
  
  return (
  <main className='about'>
      <AboutHeader/>
      <AboutContent/>
      <Invitation/>
   </main>
  )
}
