import './footer.scss'
import {Link} from 'react-router-dom'

export default function footer() {
  return (
   <footer className='footer'>
    <div className="map-div">
        <div className="map-heading-div">
            <h4>You can find us at...</h4>
        </div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.18394901733!2d-73.98811752487106!3d40.75797873480095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25855c6480299%3A0x55194ec5a1ae072e!2sTimes%20Square!5e0!3m2!1sen!2s!4v1692030911961!5m2!1sen!2s"  height="300" style={{border: '0',filter: 'invert(90%)'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
       <div className="page-links-div">
        <ul>
            <li><Link to="/" className='link'>Home</Link></li>
            <li><Link to="/about" className='link'>About</Link></li>
            <li><Link to="/portfolio" className='link'>Portfolio</Link></li>
            <li><Link to="/contact" className='link'>Contact</Link></li>
            <li><Link to="/" className='link'>Privacy Policy</Link></li>
        </ul>

    </div>
    <div className="credits-div">
        <h3>ThePostWedding</h3>
        <h4>Designed and Developed by <a href="/">Huzaifa</a></h4>
    </div>
    <div className="social-links-div">
       <ul>
            <li><a href="/">Facebook</a></li>
            <li><a href="/">Instagram</a></li>
            <li><a href="/">Linkedin</a></li>
            <li><a href="/">Youtube</a></li>
        </ul>
    </div>
   </footer>
  )
}
