

import {videoSources} from './videosources'
export default function Videos() {

    return (
        <div>
            <div className="videos-container">

          {videoSources.map(videos => 
        <div key={videos.id} className={videos.category}>
        
        <iframe width="560" height="315" src={videos.src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>)}


            </div>
        </div>
    )
}
