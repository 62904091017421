import './process-section.scss'
import { gsap } from "gsap";
import { useRef, useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

export default function ProjectsSection() {
    const questionRef = useRef(null);
    const containerRef = useRef(null);
    const comp = useRef();
    useEffect(()=>{
        let ctx = gsap.context(()=>{
            gsap.to(questionRef.current,{
                scrollTrigger:{
                    trigger: containerRef.current,
                    start: 'top center',
                    // end: '+=1500',
                    // end: 'bottom top',
                    pin: questionRef.current,     
                    pinSpacing: true,
                    scrub: true ,
                    invalidateOnRefresh: true,
                },
            })
        } , comp)

        return ()=> ctx.revert();


    } ,[])

    return (
        <section className='home-fourth-section process-section'>

            <div className="process-section-heading">
                <h3>How It Works?</h3>
            </div>

            <div className="process-steps-wrapper">
             



<div className="process-steps-container">
                {/* Step One */}
                <div className="process-step-div">
                    <span className="step-number-span">
                        1
                    </span>
                    <div className="process-step-content">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. In voluptates ipsum adipisci ut. Voluptatibus eligendi, quam necessitatibus culpa ex possimus?
                    </div>
                </div>
                {/* Step Two */}
                <div className="process-step-div">
                    <span className="step-number-span">
                        2
                    </span>
                    <div className="process-step-content">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. In voluptates ipsum adipisci ut. Voluptatibus eligendi, quam necessitatibus culpa ex possimus?
                    </div>
                </div>
                {/* Step Three */}
                <div className="process-step-div">
                    <span className="step-number-span">
                        3
                    </span>
                    <div className="process-step-content">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. In voluptates ipsum adipisci ut. Voluptatibus eligendi, quam necessitatibus culpa ex possimus?
                    </div>
                </div>
                {/* Step Four */}
                <div className="process-step-div">
                    <span className="step-number-span">
                        4
                    </span>
                    <div className="process-step-content">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. In voluptates ipsum adipisci ut. Voluptatibus eligendi, quam necessitatibus culpa ex possimus?
                    </div>
                </div>
</div>

<div className="question-mark-container" ref={containerRef}>
<span className="question-mark" ref={questionRef}>
                    <p>
                        ?
                    </p>
                </span>
</div>

            </div>
        </section>
    )
}
