import './invitation.scss'


export default function invitation() {
  return (
   <section className="invitation-section">
    <h3>Ready To Talk ?</h3>
    <a href="/contact">Let's Go</a>

   </section>
  )
}
