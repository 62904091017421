import './services-section.scss'
import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import { faCoffee } from '@fortawesome/free-regular-svg-icons' 

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { gsap } from "gsap";
import { useRef, useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
library.add(fas)




export default function ServicesSection() {
  gsap.registerPlugin(ScrollTrigger);

  // Refrences to Scroll Trigger Elements
  const highlightsRef = useRef(null)
  const fullVersionsRef = useRef(null)
  const teasersRef = useRef(null)
  const reelsRef = useRef(null)
  const retouchingRef = useRef(null)
  const albumRef = useRef(null)
  const comp = useRef()
  useEffect(() => {

    let servicesScroll = gsap.context(()=>{
      const tl = gsap.timeline({})
      // Highlights Div
      tl.to(highlightsRef.current, {
        backgroundSize: '100% 100%',
        color: 'white',
        scrollTrigger: {
          trigger: highlightsRef.current,
        scrub: 1,
        start: 'top bottom',
        end: 'top center',
        // toggleClass: 'add-bg'
        }
      })
      // Full Versions Div
      tl.to(fullVersionsRef.current, {
        backgroundSize: '100% 100%',
        color: 'white',
        scrollTrigger: {
          trigger: fullVersionsRef.current,
          scrub: 1,
          start: 'top bottom',
          end: 'top center',
          // delay: 1
        }
      })
  // Teasers Div
  tl.to(teasersRef.current, {
    backgroundSize: '100% 100%',
        color: 'white',
    scrollTrigger: {
      trigger: teasersRef.current,
      scrub: 1,
      start: 'top bottom',
      end: 'top center',
      // delay: 2
    }
  })
  // Reels Div
  tl.to(reelsRef.current, {
    backgroundSize: '100% 100%',
    color: 'white',
    scrollTrigger: {
      trigger: reelsRef.current,
      scrub: 1,
      start: 'top bottom',
      end: 'top center'
    }
  })
  // retouching Div
  tl.to(retouchingRef.current, {
    backgroundSize: '100% 100%',
        color: 'white',
    scrollTrigger: {
      trigger: retouchingRef.current,
      scrub: 1,
      start: 'top bottom',
      end: 'top center'
    }
  })
  // Album Div
  tl.to(albumRef.current, {
    backgroundSize: '100% 100%',
    color: 'white',
    scrollTrigger: {
      trigger: albumRef.current,
      scrub: 1,
      start: 'top bottom',
      end: 'top center',
    }
  })
    } , comp)

    return ()=> servicesScroll.revert();
    
  },[])


  return (
    <section className='services-section third-section-home'>
      {/* <div className="video-div">
  
    <video className='technologies-video' src={technologiesVideo} autoPlay loop muted></video>
    <div className="overlay"></div>
    </div> */}

      <div className="heading-div">

        <h3>We  Offer</h3>
      </div>

      <div className="services-div-container ">
        {/* Highlights */}
        <div className="highlights-div services-div odd" ref={highlightsRef}>
          <FontAwesomeIcon icon="fa-solid fa-video" size='2xl' />
          <h3>Highlights</h3>
          <p> Our experienced editors are not just technicians; they're artists who understand the power of storytelling. They meticulously select, trim, and arrange clips to create a visual masterpiece that showcases your content's essence..</p>
        </div>

        {/* Full Versions */}
        <div className="highlights-div services-div even" ref={fullVersionsRef}>
          <FontAwesomeIcon icon="fa-solid fa-file-video" size='2xl' />
          <h3>Full Versions</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam ratione error eos impedit dolorem minus minima nemo magni illum dolore tenetur voluptas beatae, iure cum nobis.</p>
        </div>

        {/* Teasers */}
        <div className="highlights-div services-div odd" ref={teasersRef}>
          <FontAwesomeIcon icon="fa-solid fa-film" size='2xl' />
          <h3>Teasers</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam ratione error eos impedit dolorem minus minima nemo magni illum dolore tenetur voluptas beatae, iure cum nobis.</p>
        </div>

        {/* Reels */}
        <div className="highlights-div services-div even }" ref={reelsRef}>
          <FontAwesomeIcon icon="fa-solid fa-clapperboard" size='2xl' />
          <h3>Reels</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam ratione error eos impedit dolorem minus minima nemo magni illum dolore tenetur voluptas beatae, iure cum nobis.</p>
        </div>

        {/* Single Photo Retouches */}
        <div className="highlights-div services-div odd" ref={retouchingRef}>
          <FontAwesomeIcon icon="fa-solid fa-image" size='2xl' />
          <h3>Single Photo Retouches</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam ratione error eos impedit dolorem minus minima nemo magni illum dolore tenetur voluptas beatae, iure cum nobis.</p>
        </div>

        {/* Album Making */}
        <div className="highlights-div services-div even" ref={albumRef}>
          <FontAwesomeIcon icon="fa-solid fa-images" size='2xl' />
          <h3>Album Making</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam ratione error eos impedit dolorem minus minima nemo magni illum dolore tenetur voluptas beatae, iure cum nobis.</p>
        </div>


      </div>

    </section>
  )
}
