import './contact.css'
import Header from '../header/header'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas,fab)


export default function Contact() {
  return (
    <>
    <Header/>
    <section className='contact-section'>
    <h3>Let's make something great together</h3>
    <div className="content">
        <div className="extra-info">

            <div className="extra-info-columns">
                <h3>Office</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum velit quae, ex facere quidem et.</p>
            </div>
            <div className="extra-info-columns">
                <h3>Email</h3>
                <p>info@tpw.com</p>
            </div>
            <div className="extra-info-columns">
                <h3>Phone</h3>
                <p>+93028849292</p>
            </div>
            <div className="social-icons">
            <a href="/"><FontAwesomeIcon icon="fa-brands fa-facebook" className='icons' /></a> 
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-instagram" className='icons'/></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-linkedin" className='icons'/></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-youtube" className='icons' /></a>
            </div>
        </div>
        <div className="contact-form">
            <form action="post">
                <input type="text" placeholder='Your name?'/>
                <input type="text" placeholder='Your email?'/>
                <textarea name="messgae" placeholder='Your Message' id="" cols="30" rows="10"></textarea>
               <div>
               <button type='submit'>Submit</button>
                </div> 
            </form>
        </div>
    </div>
   </section>
    </>
 
  )
}
