import './navbar.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
library.add(fas,fab)

export default function Navbar() {

  
  function hideNav(){
    setTimeout(()=>{
      document.querySelector('.nav-content-div').style.opacity= '0';
     }, 100)
     setTimeout(()=>{
      document.querySelector('.column-one').style.display = 'flex';
      document.querySelector('.column-two').style.display = 'flex';
      document.querySelector('.column-three').style.display = 'flex';
      document.querySelector('.column-four').style.display = 'flex';
    },500)
    setTimeout(()=>{
      document.querySelector('.nav-content-container').style.display = 'none';
    },500)
    setTimeout(()=>{
      document.querySelector('.column-one').style.transform = 'translateY(-100vh)';
    },700)
    setTimeout(()=>{
      document.querySelector('.column-two').style.transform = 'translateY(-100vh)';
    },900)
    setTimeout(()=>{
      document.querySelector('.column-three').style.transform = 'translateY(-100vh)';
    },1000)
    setTimeout(()=>{
      document.querySelector('.column-four').style.transform = 'translateY(-100vh)';
    },1200)
    setTimeout(()=>{
      document.querySelector('.navbar').style.display = 'none';
    }, 1300)
    console.log('else')
  
}

  
  
  return (
    <nav className="navbar">
    <div className="column-one columns"></div>
    <div className="column-two columns"></div>
    <div className="column-three columns"></div>      
    <div className="column-four columns"></div>

    <div className="nav-content-container">
      <div className="nav-content-div">
        {/* First Section */}
      <section className="first-section">
      <div className="logo-div">
            <h2>TPW</h2>
        </div>
        <div className="links-icon-div"> 
            <div className="links-icon flex column close-icon" onClick={hideNav}>
                <span className="links-icon-line links-icon-first-line">
                </span>
                <span className="links-icon-line links-icon-second-line">
                </span>
            </div>
        </div>
      </section>
      {/* Second Section */}
      <section className='second-section'>
        <div className='page-links-div'>
        <ul>
            <li className='nav-links' onClick={hideNav}><Link to="/">Home</Link></li>
            <li className='nav-links' onClick={hideNav}><Link to="/about">About</Link></li>
            <li className='nav-links' onClick={hideNav}><Link to="/portfolio">Portfolio</Link></li>
            <li className='nav-links' onClick={hideNav}><Link to="/contact">Contact</Link></li>
        </ul>
        </div>

        <div className='info-div'>
       <div className="social-div">
        <h3>Let's Connect</h3>
        <div className="icons-div">
       <a href="/"><FontAwesomeIcon icon="fa-brands fa-facebook" className='icons' /></a> 
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-instagram" className='icons'/></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-linkedin" className='icons'/></a>
        <a href="/"><FontAwesomeIcon icon="fa-brands fa-youtube" className='icons' /></a>
        </div>
       </div>
       <div className="address-div">
       <h3>Let's Meet</h3>
       <p>27 North Green Lake Street
North Wales, PA 19454</p>
       </div>
        </div>
      </section>
      </div>
     
    </div>
  </nav>

  )
}
