import './testimonials-section.scss'




export default function TestimonialsSsection() {

    return (
        <section className="testimonials-section-home fourth-section-home">
            <div className="testimonials-heading">
                <h3>
                    Don't Believe Us
                    <br />
                    Believe Our
                    Customers


                </h3>

            </div>
            <div className="testimonial-container">
                {/* <!-- ITEM 1 --> */}

                <div className="testimonial-div one">
                    <div className="testimonial-img-div">
                        <img src="https://image.cnbcfm.com/api/v1/image/104410446-GettyImages-669889288.jpg?v=1529474846" alt="" />
                    </div>
                    {/* <div className="testimonial-content-div"> */}

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam soluta facere nostrum explicabo a.
                            Dolorum ullam aperiam delectus voluptas quis. Ipsam iusto eum quisquam dolore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, et laboriosam?</p>
                        <h4>Mark Zuckerber</h4>
                        <h5>CEO Of Facebook</h5>
                    {/* </div> */}

                </div>

            </div>

        </section>
    )
}
