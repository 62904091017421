import './hero.scss'
import HeroVideo from '../../assets/hero-video.mp4'

export default function HeroHeader() {
  function showNav() {
    document.querySelector('.navbar').style.display = 'flex';
    setTimeout(() => {
      document.querySelector('.column-one').style.transform = 'translateY(0)';
    }, 100)
    setTimeout(() => {
      document.querySelector('.column-two').style.transform = 'translateY(0)';
    }, 200)
    setTimeout(() => {
      document.querySelector('.column-three').style.transform = 'translateY(0)';
    }, 300)
    setTimeout(() => {
      document.querySelector('.column-four').style.transform = 'translateY(0)';
    }, 400)
    setTimeout(() => {
      document.querySelector('.nav-content-container').style.display = 'flex';
    }, 800)
    setTimeout(() => {
      document.querySelector('.column-one').style.display = 'none';
      document.querySelector('.column-two').style.display = 'none';
      document.querySelector('.column-three').style.display = 'none';
      document.querySelector('.column-four').style.display = 'none';
    }, 1000)
    setTimeout(() => {
      document.querySelector('.nav-content-div').style.opacity = '1.0';
    }, 1200)
    console.log('if')
  }

  return (
    <>

      <header className=" header-home column">

        <div className="video-div">
          <div className="overlay"></div>
          <video src={HeroVideo} muted autoPlay loop></video>

        </div>
        <section className="first-section flex">
          <div className="logo-div">
            <h2 >TPW</h2>
          </div>
          <div className="links-icon-div">
            <div className="links-icon flex column" onClick={showNav}>
              <span className="links-icon-line links-icon-first-line">
              </span>
              <span className="links-icon-line links-icon-second-line">
              </span>
            </div>
          </div>
        </section>
        <section className='second-section'>

          <div className='first column'>
            <h3>We'll help you tell your story.</h3>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur amet odio soluta exercitationem necessitatibus harum!</p>
          </div>

        </section>
      </header>
    </>
  )
}


