// import logo from './logo.svg';
import './App.css';
import ScrollToTop from "./scroll-to-top";
// import HeroHeader from './components/home/hero-header'
// import ProjectsSection from './components/home/projects-section'
// import ServicesSection from './components/home/services-section'
// import TestimonialsSection from './components/home/testimonials-section'
// import Invitation from './components/home/invitation'

import Footer from './components/footer/footer'
import Navbar from './components/header/navbar'
import Home from './components/home/home'
import About from './components/about/about'
import Portfolio from './components/portfolio/portfolio'
import Contact from './components/contact/contact'
import {BrowserRouter,Route, Routes} from "react-router-dom"
import { useState } from 'react';


function App() {

  
  return (
  
    <BrowserRouter>
    <ScrollToTop />
    <Navbar/>
    <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/portfolio" element={<Portfolio/>}/>
    </Routes>
    <Footer/>
   </BrowserRouter>
  
   );
 
}

export default App;
