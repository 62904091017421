import './content.scss'
import { useEffect } from 'react'

export default function Content() {
   useEffect(()=>{
   
   let buttons = document.querySelectorAll(".portfolio-buttons");
   for(let i=0;i<buttons.length;i++){
    buttons[i].addEventListener('click',function(){
       buttons[i].classList.add('add-btn-bg');
       let otherButtons = document.querySelectorAll(`.portfolio-buttons-div > button:not(.${buttons[i].getAttribute('data-btn-category')}`);
       for (let j=0;j<otherButtons.length;j++){
        if(otherButtons[j].classList.contains('add-btn-bg')){
            otherButtons[j].classList.remove('add-btn-bg')
        }
       }

       //    ksjisld 
       let videosContainer = document.querySelector('.videos-container');
       let allVideos = videosContainer.querySelectorAll('div')
       let thisVideo = videosContainer.querySelectorAll(`.${buttons[i].getAttribute('data-btn-category')}`)
       let otherElements = document.querySelectorAll(`.videos-container > div:not(.${buttons[i].getAttribute('data-btn-category')}`);

       if(buttons[i].getAttribute('data-btn-category') === 'all'){
        for(let j=0;j<allVideos.length;j++){
            allVideos[j].style.display = 'flex';
           }
       } 
       else{
        for(let j=0;j<thisVideo.length;j++){
            thisVideo[j].style.display = 'flex';
           }
           for(let j=0;j<otherElements.length;j++){
            otherElements[j].style.display = 'none';
           }
       }

     
   


    })
   }
   
   
   })

  return (
    <section className='portfolio-content-section'>
        <h3>Portfolio</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati assumenda quidem eligendi nam impedit error, suscipit quos tempora neque necessitatibus repellendus cumque delectus soluta incidunt labore illum ea consectetur nihil iusto iste debitis animi. Saepe possimus est quibusdam soluta corporis earum officiis cum ipsum explicabo.</p>
        <div className='portfolio-buttons-div'>
            <button className='portfolio-buttons all add-btn-bg' data-btn-category='all'>All</button>
            <button className='portfolio-buttons teasers' data-btn-category='teasers'>Teasers</button>
            <button className='portfolio-buttons reels' data-btn-category='reels'>Reels</button>
            <button className='portfolio-buttons highlights' data-btn-category='highlights'>Highlights</button>
            <button className='portfolio-buttons full-video' data-btn-category='full-video'>Full Videos</button>
        </div>
    </section>
  )
}
