export let videoSources = [
    {
        title: 'iceland wedding',
        src: 'https://www.youtube-nocookie.com/embed/YKnXWyEUns0',
        id: 1,
        category: 'highlights',
    },
    {
        title: 'swedish wedding',
        src: 'https://www.youtube-nocookie.com/embed/Un6KCnfKaNw',
        id: 2,
        category: 'teasers',
    },
    {
        title: 'italy wedding one',
        src: 'https://www.youtube-nocookie.com/embed/PEIgbYXgKQk',
        id: 3,
        category: 'reels'
    },
    {
        title: 'italy wedding two',
        src: 'https://www.youtube-nocookie.com/embed/VDbPFqltwUw',
        id: 4,
        category: 'highlights'
    },
    {
        title: 'italy wedding three',
        src: 'https://www.youtube-nocookie.com/embed/j76ISXzUD8M',
        id: 5,
        category: 'full-video'
    },
    {
        title: 'Taylor and Sophia',
        src: 'https://www.youtube.com/embed/HzDgQsemw-g',
        id: 7,
        category: 'teasers'
    },
    {
        title: 'Sanne Vloet',
        src: 'https://youtube.com/embed/PEIgbYXgKQk',
        id: 8,
        category: 'reels'
    },
    {
        title: 'Bailey',
        src: 'https://youtube.com/embed/kqUUPueWUxE',
        id: 9,
        category: 'highlights'
    },
    {
        title: 'whitney',
        src: 'https://youtube.com/embed/fq2CdnfijhQ',
        id: 10,
        category: 'full-video'
    },
    {
        title: 'Anne',
        src: 'https://www.youtube-nocookie.com/embed/YKnXWyEUns0',
        id: 11,
        category: 'teasers'
    },
    {
        title: 'vows',
        src: 'https://youtube.com/embed/3riDirpvb0o',
        id: 12,
        category: 'reels'
    },

]
