import Video from './videos'
import Content from './content'
import Header from '../header/header'
import './portfolio.scss'
import Invitation from '../home/invitation'
import { useState,useEffect } from 'react'
export default function Portfolio() {
  

  return (
    <main>
        <Header/>
        <Content/>
      <Video/>
      <Invitation/>
    </main>
  )
}
