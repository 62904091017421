import './about-content.scss'

import { useRef, useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

export default function AboutContent() {
    let firstHeading = useRef(null)
    let secondHeading = useRef(null);
    let firstText = useRef(null);
    let secondText = useRef(null);
    let containerRef = useRef(null)
    const comp = useRef();
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        let height = window.innerHeight;
        console.log(height)

        let textAnimate = gsap.context(() => {
            let textTimeline = gsap.timeline({

                scrollTrigger: {
                    trigger: containerRef.current,
                    pin: containerRef.current,
                    start: "top top",
                    end: `bottom top`,
                    scrub: true,
                    // markers: true
                }
            })
            textTimeline.to(firstHeading.current,
                {
                    duration: 3,
                    text: {
                        value: 'Who We Are?'
                    }

                })
            textTimeline.to(firstText.current,
                    {
                        duration: 3,
                        text: {
                            value: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores fugiat voluptas, odit saepe alias a molestiae commodi vel architecto labore ut iusto maiores tenetur eligendi doloribus quas, temporibus deserunt neque quis dolore odio quaerat! Ratione. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores fugiat voluptas, odit saepe alias a molestiae commodi vel architecto labore ut iusto maiores tenetur eligendi doloribus quas, temporibus deserunt neque quis dolore odio quaerat! Ratione.'
                        }
    
                    })
                    textTimeline.to(secondHeading.current,
                        {
                            duration: 3,
                            text: {
                                value: 'What We Do?'
                            }
        
                        })
                    textTimeline.to(secondText.current,
                            {
                                duration: 3,
                                text: {
                                    value: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores fugiat voluptas, odit saepe alias a molestiae commodi vel architecto labore ut iusto maiores tenetur eligendi doloribus quas, temporibus deserunt neque quis dolore odio quaerat! Ratione. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores fugiat voluptas, odit saepe alias a molestiae commodi vel architecto labore ut iusto maiores tenetur eligendi doloribus quas, temporibus deserunt neque quis dolore odio quaerat! Ratione. '
                                }
            
                            })
        }, comp)

        return () => {
            textAnimate.revert();
        }

    })



    return (
        <section className='about-second-section' >
            <div className="first-div">
                <h4>50+ Successful Projects</h4>
                <h4>10+ Current Projects</h4>
                <h4>20+ Happy Clients</h4>
            </div>

            <div className="second-div" ref={containerRef}>
                <h3 ref={firstHeading}></h3>
                <p ref={firstText}></p>
                <h3 ref={secondHeading}></h3>
                <p ref={secondText}></p>
            </div>

            {/* <div className="third-div">
      </div> */}
        </section>
    )
}
