import HeroHeader from './hero-header'
import ProjectsSection from './process-section'
import ServicesSection from './services-section'
import TestimonialsSection from './testimonials-section'
import Invitation from './invitation'
import RecentWork from './recent-work'
// import WhyUs from './why-us'
// import Footer from './components/footer/footer'
// import Navbar from './components/header/navbar'

export default function Home() {
  return (
   <>
   
    <HeroHeader/>
   {/* <WhyUs/> */}
   <ServicesSection/>
   <TestimonialsSection/>
   <RecentWork/>
   <ProjectsSection/>
   <Invitation/>

   </>
  )
}
